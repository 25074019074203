<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="lineItem.inputs.data"
      class="elevation-2"
      :sort-by="sortByKey"
      show-expand
      item-key="meta.id"
      :expanded.sync="expandedItems"
      dense
      :no-data-text="
        'Keine Einträge für Abrechnungsgruppe ' + lineItem.title + ' vorhanden'
      "
      :mobile-breakpoint="0"
    >
      <template v-slot:top>
        <v-card flat dense>
          <v-card-title class="body-2 v-card__subtitle"
            ><v-icon left small class="mr-2">{{ headerIcon }}</v-icon
            >{{ lineItem.title }}
            <v-spacer></v-spacer>
            <v-btn
              depressed
              small
              class="mr-2"
              :disabled="lineItem.inputs.data.length === expandedItems.length"
              @click="expandedItems = lineItem.inputs.data"
              ><v-icon left>mdi-expand-all</v-icon>Alle ausklappen</v-btn
            >
            <v-btn
              depressed
              small
              @click="expandedItems = []"
              :disabled="!expandedItems.length"
              ><v-icon left>mdi-collapse-all</v-icon>Alle einklappen</v-btn
            >
          </v-card-title>
        </v-card>
      </template>
      <template v-slot:item.title="{ item }">
        <div class="d-flex flex-column">
          <span class="d-block font-weight-semibold text-truncate"
            >{{ item.title }}

            <v-tooltip bottom v-if="item.meta.id">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  x-small
                  text
                  color="primary"
                  v-bind="attrs"
                  v-on="on"
                  :to="{
                    name: 'accounting-time-sheets-details',
                    params: {
                      organizationId: $route.params.organizationId,
                      itemId: item.meta.id,
                    },
                  }"
                  ><v-icon x-small>mdi-open-in-app</v-icon></v-btn
                >
              </template>
              <span>Stundenmeldung öffnen</span>
            </v-tooltip>
          </span>
        </div>
      </template>
      <template v-slot:item.payrollType.title="{ item }">
        <v-chip v-if="item.payrollType.title" small outlined label>{{
          item.payrollType.title
        }}</v-chip>
      </template>
      <template v-slot:item.type="{ item }">
        <time-sheet-type-chip :type="item.type" small> </time-sheet-type-chip>
      </template>
      <template v-slot:item.totalSalary.amount="{ item }">
        <pre>{{ item.totalSalary.amount }} {{ item.totalSalary.currency }}</pre>
      </template>
      <template v-slot:[`item.billableDuration.start.timestamp`]="{ item }">
        {{ timestampConverter(item.billableDuration.start.timestamp) }}
      </template>
      <template v-slot:[`item.billableDuration.end.timestamp`]="{ item }">
        {{ timestampConverter(item.billableDuration.end.timestamp) }}
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="mx-0 px-0">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="6" md="3">
                <v-card outlined>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.additionalData?.location?.name
                          ? item.additionalData.location.name
                          : "N/A"
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular"
                        >Ort</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-card outlined>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        item.workedDuration.inHours
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular"
                        >Geleistete Zeit in H
                        (Tatsächlich)</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-card outlined>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        timestampConverter(item.workedDuration.start.timestamp)
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular"
                        >Startzeitpunkt (Tatsächlich)</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" sm="6" md="3">
                <v-card outlined>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>{{
                        timestampConverter(item.workedDuration.end.timestamp)
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular"
                        >Endzeitpunkt (Tatsächlich)</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" v-if="item.description">
                <v-card outlined>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.description }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular"
                        >Bemerkungen</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <!-- courseTime -->
              <v-col cols="12" v-if="item.additionalData.files.length > 0">
                <v-card outlined>
                  <v-card-text class="pb-0 mb-0">
                    <v-chip
                      v-for="(file, index) in item.additionalData.files"
                      :key="index"
                      outlined
                      label
                      small
                      class="ma-1 font-weight-medium"
                      @click="openFile(file.path)"
                      ><v-icon small left>{{ getFileIcon(file.type) }}</v-icon
                      >{{ file.name }}</v-chip
                    >
                  </v-card-text>
                  <v-list-item dense>
                    <v-list-item-content>
                      <v-list-item-subtitle class="font-weight-regular"
                        >Dateien</v-list-item-subtitle
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12"
                ><v-btn
                  text
                  color="primary"
                  outlined
                  class="mr-2"
                  :to="{
                    name: 'accounting-time-sheets-details',
                    params: {
                      organizationId: $route.params.organizationId,
                      itemId: item.meta.id,
                    },
                  }"
                  ><v-icon left small>mdi-open-in-new</v-icon>Stundenmeldung
                  öffnen</v-btn
                ><v-btn
                  v-if="item.connectedDutyReportId"
                  text
                  color="primary"
                  outlined
                  :to="{
                    name: 'duty-management-reports-details',
                    params: {
                      organizationId: $route.params.organizationId,
                      itemId: item.connectedDutyReportId,
                    },
                  }"
                  ><v-icon left small>mdi-open-in-new</v-icon>Dienstbericht
                  öffnen</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { storage } from "@/firebase";
import TimeSheetTypeChip from "@/components/accounting/time-sheets/TimeSheetTypeChip.vue";

export default {
  name: "payslips-data-table",
  props: {
    lineItem: {
      type: Object,
      required: true,
    },
  },
  components: {
    TimeSheetTypeChip,
  },
  data() {
    return {
      expandedItems: [],
      footerProps: {
        "items-per-page-options": [15, 25, 50, 100, -1],
      },
    };
  },
  computed: {
    payslipsOutput() {
      return this.payslips.filter((leave) => {
        if (this.payslipsPersonFilter === "own") {
          return leave.user.uid === this.currentUserUid;
        }
        return true;
      });
    },
    headers() {
      switch (this.lineItem.displayType) {
        case "dutyTime":
          return [
            { text: "Tätigkeit", value: "title", divider: true },
            {
              text: "Ort",
              value: "additionalData.location.name",
              divider: true,
            },
            {
              text: "Einsatzmittel",
              value: "additionalData.assignedPosition.unit.title",
              divider: true,
            },
            {
              text: "Funktion",
              value: "additionalData.assignedPosition.position.shortTitle",
              divider: true,
            },
            { text: "Quelle", value: "type", divider: true },
            {
              text: "Abrechnungsart",
              value: "payrollType.title",
              divider: true,
            },
            {
              text: "Startzeitpunkt (Abrechenbar)",
              value: "billableDuration.start.timestamp",
              divider: true,
            },
            {
              text: "Endzeitpunkt (Abrechenbar)",
              value: "billableDuration.end.timestamp",
              divider: true,
            },
            {
              text: "Abrechenbare Zeit in H",
              value: "totalSalary.baseVariables.HOURS",
              align: "end",
              divider: true,
            },
            {
              text: "Summe in EUR",
              value: "totalSalary.amount",
              align: "end",
              divider: true,
            },
            { text: "", value: "data-table-expand", width: "1%" },
          ];
        case "courseTime":
          return [
            {
              text: "Lehrgangsbezeichnung",
              value: "additionalData.externalName",
            },
            { text: "Lehrgangskennung", value: "additionalData.externalId" },
            { text: "Abrechnungsart", value: "payrollType.title" },
            {
              text: "Startzeitpunkt (Abrechenbar)",
              value: "billableDuration.start.timestamp",
            },
            {
              text: "Endzeitpunkt (Abrechenbar)",
              value: "billableDuration.end.timestamp",
            },
            {
              text: "Abrechenbare Zeit in D",
              value: "billableDuration.inHours",
              align: "end",
            },
            {
              text: "Summe in EUR",
              value: "totalSalary.amount",
              align: "end",
              divider: true,
            },
            { text: "", value: "data-table-expand", width: "1%" },
          ];
        case "dutyPositionTime":
          return [
            { text: "Tätigkeit", value: "title", divider: true },
            { text: "Quelle", value: "type", divider: true },
            {
              text: "Abrechnungsart",
              value: "payrollType.title",
              divider: true,
            },
            {
              text: "Startzeitpunkt (Abrechenbar)",
              value: "billableDuration.start.timestamp",
              divider: true,
            },
            {
              text: "Endzeitpunkt (Abrechenbar)",
              value: "billableDuration.end.timestamp",
              divider: true,
            },
            {
              text: "Summe in EUR",
              value: "totalSalary.amount",
              align: "end",
              divider: true,
            },
            { text: "", value: "data-table-expand", width: "1%" },
          ];
        case "default":
        default:
          return [
            { text: "Tätigkeit", value: "title", divider: true },
            { text: "Quelle", value: "type", divider: true },
            {
              text: "Abrechnungsart",
              value: "payrollType.title",
              divider: true,
            },
            {
              text: "Startzeitpunkt (Abrechenbar)",
              value: "billableDuration.start.timestamp",
              divider: true,
            },
            {
              text: "Endzeitpunkt (Abrechenbar)",
              value: "billableDuration.end.timestamp",
              divider: true,
            },
            {
              text: "Abrechenbare Zeit in H",
              value: "totalSalary.baseVariables.HOURS",
              align: "end",
              divider: true,
            },
            {
              text: "Summe in EUR",
              value: "totalSalary.amount",
              align: "end",
              divider: true,
            },
            { text: "", value: "data-table-expand", width: "1%" },
          ];
      }
    },
    sortByKey() {
      return "title";
    },
    headerIcon() {
      return "mdi-clock-time-four-outline";
    },
    noDataText() {
      return "";
    },
  },
  methods: {
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
  openFile(filePath) {
    const storageRef = storage.ref();
    // Create a reference to the file
    const fileRef = storageRef.child(filePath);

    // Get the download URL
    fileRef
      .getDownloadURL()
      .then((url) => {
        // Open the file in a new tab
        window.open(url, "_blank");
      })
      .catch((error) => {
        console.error("Error getting download URL:", error);
      });
  },
  getFileIcon(fileType) {
    let [generalType, specificType] = fileType.split("/");
    switch (generalType) {
      case "application":
        switch (specificType) {
          case "pdf":
            return "mdi-file-pdf-box";
          case "msword":
          case "vnd.openxmlformats-officedocument.wordprocessingml.document":
            return "mdi-file-word";
          case "vnd.ms-excel":
          case "vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return "mdi-file-excel";
          case "vnd.ms-powerpoint":
          case "vnd.openxmlformats-officedocument.presentationml.presentation":
            return "mdi-file-powerpoint";
          case "zip":
          case "x-rar-compressed":
          case "x-7z-compressed":
            return "mdi-folder-zip";
          case "plain":
            return "mdi-file-document";
          default:
            return "mdi-file";
        }
      case "image":
        return "mdi-file-image";
      case "audio":
        return "mdi-file-music";
      case "video":
        return "mdi-file-video";
      default:
        return "mdi-file";
    }
  },
};
</script>
